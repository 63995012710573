import { Hero } from "@components/Hero/Hero";
import * as styles from './_ExperimentsHero.module.scss';
import React from "react";
import FREEZER_FEATURE_VIDEO from "../../_assets/Genemmod-ELN-v3.mp4";

export const ExperimentsHero = () => {
  const props = {
		title: 'Streamlined lab experiment management software',
		description: 'Upgrade your lab experience with effortless navigation, real-time teamwork, and seamless inventory integration.',
		image: (
			<div className={styles.heroImage}>
         <video
					className={styles.videoContainer}
					muted
					autoPlay
					loop
					playsInline
					preload="auto"
				>
					<source src={FREEZER_FEATURE_VIDEO} type="video/mp4" />
				</video>
			</div>
		)
	}

	return <Hero {...props} tabletTextAlignment="center" heroTextContainerSize="small" />
}